<template>
  <div class="w-full">
    <search-input
      class="mb-8"
      @input="onSearch($event, 100)"
      placeholder="Search"
    />
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div v-for="(employee) in employees" :key="employee.id" class="flex flex-col border-b border-dashed border-romanSilver p-2">
        <div class="flex items-center gap-2">
          <div class="flex justify-center w-9 h-9 rounded-md">
            <img class="rounded-md w-9 h-9" :src="employee.photo" v-if="employee.photo" />
            <span v-else class="w-9 h-9 text-blueCrayola border text-center font-semibold rounded-md p-1 flex justify-center items-center">
              {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
            </span>
          </div>
          <div class="flex flex-col flex-grow">
            <p>{{ `${employee.fname} ${employee.lname}` }}</p>
            <div class="text-xs flex font-semibold text-romanSilver uppercase">
              <p class="mr-2">{{ !employee.orgFunction ? "-" : employee.orgFunction.name }}</p>
              <p class="mr-2">{{ employee.userDesignation && `• ${employee.userDesignation.name}` }}</p>
            </div>
          </div>
          <input
            type="radio"
            :value="employee"
            v-model="selectedEmployee"
            @input="$emit('selected', employee)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";

export default {
  components: {
    SearchInput
  },
  data(){
    return {
      selectedEmployee: {},
      employees: [],
      timeout: null,
      loading: true
    }
  },
  methods: {
    onSearch(query, items) {
      this.loading = true
      clearTimeout(this.timeout);
      if (!query) {
        this.$_getAllEmployeesWithQueries(`&perPage=${items}`)
        .then(({ data }) => {
          this.employees = data.data.employees
          this.loading = false
        })
      }
      this.timeout = setTimeout(() => {
        this.$_getAllEmployeesWithQueries(
          `&search=${query}&perPage=${items}`
        ).then(({ data }) => {
          this.employees = data.data.employees
          this.loading = false
        })
      }, 500)
    }
  },

  mounted() {
    this.onSearch('', 3);
  }
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}
input[type="radio"]:checked {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
